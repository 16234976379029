import Oidc, { UserManager } from 'oidc-client';
import { getRuntimeSettings } from 'src/providers/GetSettings';

Oidc.Log.level = Oidc.Log.ERROR;
Oidc.Log.logger = console;

const createUserManager = () => {
  const settings = getRuntimeSettings();

  const authority =
    settings?.REACT_APP_IDENTITY_OAUTH ??
    'https://qa.accounts.internal.myrxplan.com';

  const config = {
    client_id: '546f8e1c-a367-451f-8d7d-37356a37911a',
    redirect_uri: `${window.location.protocol}//${window.location.host}/auth-callback.html`,
    response_type: 'code',
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.host}/`,
    scope:
      'offline_access openid profile rxsense.api.member rxsense.api phone rxsense.identityservice2.user',
    authority,
    automaticSilentRenew: true,
    loadUserInfo: true,
    userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
  };

  return new Oidc.UserManager(config);
};

export class UserManagerFactory {
  private static instance: UserManagerFactory;
  private userManager: UserManager | null = null;

  private constructor() {}

  static getInstance(): UserManagerFactory {
    if (!UserManagerFactory.instance) {
      UserManagerFactory.instance = new UserManagerFactory();
    }
    return UserManagerFactory.instance;
  }

  getUserManager(): UserManager {
    if (!this.userManager) {
      this.userManager = createUserManager();
    }
    return this.userManager;
  }
}

const userManagerFactory = UserManagerFactory.getInstance();

export function getUserManager(): UserManager {
  return userManagerFactory.getUserManager();
}
