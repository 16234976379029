import axios from 'axios';
import TagManager from 'react-gtm-module';
import { RuntimeEnvironmentSettings } from '../types/environmentSettings';

export class RuntimeSettingsManager {
  private static instance: RuntimeSettingsManager;
  private settings: RuntimeEnvironmentSettings | null = null;

  private constructor() {}

  static getInstance(): RuntimeSettingsManager {
    if (!RuntimeSettingsManager.instance) {
      RuntimeSettingsManager.instance = new RuntimeSettingsManager();
    }
    return RuntimeSettingsManager.instance;
  }

  async initialize(): Promise<void> {
    try {
      const response = await axios.get('configs/config.json');
      const data: RuntimeEnvironmentSettings = response.data;

      const buildEnvs = process.env;
      const settings = { ...buildEnvs, ...data };

      this.settings = settings;

      if (this.settings?.REACT_APP_GTM_ID) {
        const tagManagerArgs = {
          gtmId: this.settings.REACT_APP_GTM_ID ?? '',
        };
        TagManager.initialize(tagManagerArgs);
      }
    } catch (error) {
      console.error(error);
    }
  }

  getSettings(): RuntimeEnvironmentSettings | null {
    return this.settings;
  }
}

const runtimeSettingsManager = RuntimeSettingsManager.getInstance();

export function initializeRuntimeSettings(): Promise<void> {
  return runtimeSettingsManager.initialize();
}

export function getRuntimeSettings(): RuntimeEnvironmentSettings | null {
  return runtimeSettingsManager.getSettings();
}
