import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { User } from 'oidc-client';
import { triggerGTMEvent } from 'src/services/analytics';
import { getUserManager } from 'src/services/userManager';
import {
  getClientBrandingId,
  getClientIdFromSubdomain,
} from 'src/services/fetchClientBranding';
import { broadcastChannel } from 'src/services/broadcastChannel';
import type { MessageType } from 'src/services/broadcastChannel';
import { useTreatments } from '@splitsoftware/splitio-react';
import {
  SPLIT_ENABLE_MULTI_TENANT,
  SPLIT_TREATMENT_ON,
} from 'src/misc/constants';

export type Auth = {
  user?: User;
  isLoggedIn: boolean;
  login: () => Promise<void>;
  logout: () => Promise<void>;
};

export const AuthContext = createContext<Auth | undefined>(undefined);

export const AuthProvider: React.FC<{ user?: User }> = ({
  children,
  user: userProp,
}) => {
  const treatment = useTreatments([SPLIT_ENABLE_MULTI_TENANT]);

  const [user, setUser] = useState<User | undefined>(userProp);

  const isLoggedIn = useMemo(() => {
    return !!user && user.expired === false;
  }, [user]);

  const loadInternalUser = async () => {
    const user = await getUserManager().getUser();
    if (!!user) {
      setUser(user);
    }
    return user;
  };

  useEffect(() => {
    const init = async () => {
      const user = await loadInternalUser();
      if (!!user) {
        await broadcastChannel?.postMessage('user-signed-in');
      }
    };
    init();
  }, []);

  const login = useCallback(async () => {
    try {
      const user = await loadInternalUser();
      if (!!user) {
        return;
      }

      const clientBrandingId = getClientBrandingId();
      const subDomain = getClientIdFromSubdomain();

      await getUserManager().signinRedirect({
        extraTokenParams: {
          tenant: subDomain,
        },
        extraQueryParams: {
          client_branding_id: clientBrandingId,
          ...(treatment[SPLIT_ENABLE_MULTI_TENANT]?.treatment ===
            SPLIT_TREATMENT_ON && subDomain
            ? { tenant: subDomain }
            : {}),
        },
      });
    } catch (error: any) {
      newrelic.noticeError(error);
      window.location.href = '/error';
    }
  }, [treatment]);

  const logout = useCallback(async () => {
    await getUserManager().signoutRedirect();
    await broadcastChannel?.postMessage('user-signed-out');
  }, []);

  const broadcastChannelHandler = useCallback(
    async (msg: MessageType) => {
      if (msg === `user-signed-out`) {
        await getUserManager().signoutRedirect();
      }
      if (msg === `user-signed-in` && !isLoggedIn) {
        await loadInternalUser();
      }
    },
    [isLoggedIn],
  );

  useEffect(() => {
    getUserManager().events.addUserLoaded(setUser);
    getUserManager().events.addAccessTokenExpired(logout);
    getUserManager().events.addSilentRenewError(logout);
    broadcastChannel?.addEventListener('message', broadcastChannelHandler);
    return () => {
      getUserManager().events.removeUserLoaded(setUser);
      getUserManager().events.removeAccessTokenExpired(logout);
      getUserManager().events.removeSilentRenewError(logout);
      broadcastChannel?.removeEventListener('message', broadcastChannelHandler);
    };
  }, [logout, broadcastChannelHandler]);

  useEffect(() => {
    if (isLoggedIn) {
      triggerGTMEvent({
        event: 'login',
        method: 'email address',
      });
    }
  }, [isLoggedIn]);

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoggedIn,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
