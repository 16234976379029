import { createContext, useContext, useEffect, useState } from 'react';

import { LoaderMessage } from 'src/components/Loader';
import { EnvironmentSettings } from '../types/environmentSettings';
import { useEnvironmentSettingsQuery } from '../api/useEnvironmentSettingsQuery';
import { initializeRuntimeSettings } from 'src/providers/GetSettings';

const defaultSettings: EnvironmentSettings = {
  ContentfulApiKey: '',
  SplitIoApiKey: '',
};

export const EnvironmentSettingsContext =
  createContext<EnvironmentSettings>(defaultSettings);
export const useEnvironmentSettings = () =>
  useContext(EnvironmentSettingsContext);

const EnvironmentSettingsProvider: React.FC = ({ children }) => {
  const [isRuntimeSettingInitiliazed, setIsRuntimeSettingInitiliazed] =
    useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await initializeRuntimeSettings();

        setIsRuntimeSettingInitiliazed(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    return () => {
      // Any clean-up code here
    };
  }, []); // Make sure to adjust the dependencies array accordingly

  const { isSuccess, data, error } = useEnvironmentSettingsQuery({
    options: { enabled: isRuntimeSettingInitiliazed },
  });

  if (isRuntimeSettingInitiliazed && isSuccess && data) {
    return (
      <EnvironmentSettingsContext.Provider value={data.Result}>
        {children}
      </EnvironmentSettingsContext.Provider>
    );
  } else if (error) {
    return (
      <div>
        <h1>Error: Settings could not be retrieved!</h1>
      </div>
    );
  } else {
    return <LoaderMessage />;
  }
};

export default EnvironmentSettingsProvider;
